var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"containerx ma-6"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 justify-space-between mt-3 card flex-wrap mt-4"},[(
            _vm.stockLevelVariationsForFuels &&
              _vm.stockLevelVariationsForFuels.labels.length > 0
          )?[_c('h3',{staticClass:" font font-weight-medium font-size-md text-uppercase"},[_vm._v(" Stock Variations Over Period for fuels ")]),_c('p',{staticClass:" font blue-grey--text font-size-sm"},[_vm._v(" Overview of the stock variations over period for fuels ")]),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[(_vm.listLoading)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e(),_vm._l((_vm.stockLevelVariationsForFuels.series),function(chart,index){return _c('apexcharts',{key:index,staticClass:"d-flex box-shadow-light",attrs:{"height":"550","options":Object.assign({}, _vm.chartOptions,
                {chart: {
                  height: 400
                },
                legend: {
                  position: 'bottom',
                  fontSize: '15px',
                  fontWeight: '600'
                },
                plotOptions: {
                  borderRadius: 50
                },
                markers: {
                  size: 10
                },
                yaxis: [
                  {
                    min: 500,
                    title: {
                      text: 'Quantity'
                    }
                  }
                ],
                xaxis: {
                  type: 'Date',
                  categories: _vm.stockLevelVariationsForFuels.labels,
                  label: {
                    style: {
                      fontWeight: 'bold',
                      colors: ['black']
                    }
                  },
                  tooltip: {
                    enabled: true,
                    style: {
                      fontSize: 15
                    }
                  }
                }}),"series":chart.series}})})],2)]:_c('div',{staticClass:"d-flex flex-column justify-center flex-grow-1 mt-10",staticStyle:{"align-items":"center"}},[_c('empty-page',{attrs:{"title":'No product stock variations available for the selected duration',"subTitle":'Stock level variations of total litres sold against total litres left in the tank will show up here',"image":require("@/assets/documents.svg"),"imageWidth":"150px"}}),(_vm.listLoading)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e()],1)],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }